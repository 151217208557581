"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/react";
import { useSwiper } from "@/hooks/useSwiper";
import "swiper/css";
import Image from "next/image";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "../AppText";
import { AppButton } from "../buttons/AppButton";

import { AppIconButton } from "../buttons/AppIconButton";
import ArrowPagination from "../ArrowPagination";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../constants";
import { ChevronRight } from "lucide-react";

interface ICaseStudyCarouselSingleCardProps {
  id?: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLink: string;
  ctaLabel: string;
  background?: string;
  posts: IStudy[];
  paddingBottom?: string;
}

interface IStudy {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  image: string;
  placeholder: string;
  imgAlt: string;
  title?: string;
  excerpt?: string;
}

const CaseStudyCarouselSingleCard: React.FC<ICaseStudyCarouselSingleCardProps> = ({
  id,
  label,
  heading,
  subheading,
  ctaLink,
  ctaLabel,
  posts,
  background = "navy-blue",
  paddingBottom,
}) => {
  const { swiperRef, onSwiperChange, currentPage, onNextPageClick, onPrevPageClick, pageCount } = useSwiper();

  const { labelColor, bgColorClass, textColor, bg } = colorsMap[background] ?? fallbackColors;

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      <div className="lg:gap-16 flex flex-col gap-8">
        <div className=" lg:flex-row gap-y-8 flex flex-col">
          <div className="flex-1">
            {label && (
              <AppText className={cn("mb-1", labelColor)} type="LABEL_LARGE">
                {label}
              </AppText>
            )}

            <AppText className={cn("mb-4", textColor)} type="HEADLINE_MEDIUM">
              {heading}
            </AppText>

            <AppText className={cn(textColor)} type="SUB_HEADING_MEDIUM">
              {subheading}
            </AppText>
          </div>

          <AppButton label={ctaLabel} intent="secondary" link={ctaLink} bg={bg} />
        </div>

        <div className="relative">
          <Swiper
            spaceBetween={40}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            onInit={onSwiperChange}
            onSlideChange={onSwiperChange}
            onBreakpoint={onSwiperChange}
          >
            {posts.map((study) => renderStudy(study, background))}
          </Swiper>

          {posts.length > 1 && (
            <div className="lg:grid-cols-12 absolute inset-0 z-10 grid grid-cols-4 gap-10 pointer-events-none">
              <div className="lg:col-span-7 col-span-4"></div>
              <div className="lg:col-span-5 lg:justify-start flex items-end justify-end col-span-4">
                <ArrowPagination
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPrevPageClick={onPrevPageClick}
                  onNextPageClick={onNextPageClick}
                  type="caseStudy"
                  bg={bg}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};

export default React.memo(CaseStudyCarouselSingleCard);

const renderStudy = (study: IStudy, background: string) => {
  const { labelColor, textColor, bg, border } = colorsMap[background];

  return (
    <SwiperSlide key={study.id}>
      <div className=" lg:grid-cols-12 xl:gap-10 grid grid-cols-4 gap-10">
        <div className="relative w-full overflow-hidden lg:col-span-7 col-span-4 aspect-[16/9]">
          <Image
            src={study.image}
            alt={study.imgAlt}
            fill
            sizes="(max-width: 1024px) 100vw, 758px"
            className="object-cover w-full max-h-[419px]"
            placeholder="blur"
            blurDataURL={study.placeholder}
          />
        </div>

        <div className="lg:col-span-5 flex flex-col col-span-4 mb-20">
          <div className={cn("flex flex-col pl-8 border-l-2", border)}>
            {study.label && (
              <AppText className={cn(labelColor)} type="LABEL_LARGE">
                {study.label}
              </AppText>
            )}
            <AppText className={cn("line-clamp-3 mt-2", textColor)} type="HEADLINE_SMALL">
              {study.title}
            </AppText>

            <AppText className={cn("line-clamp-3 mt-4", textColor)} type="BODY_SMALL">
              {study.subheading}
            </AppText>

            <AppText className={cn("line-clamp-5 mt-4", textColor)} type="BODY_SMALL">
              {study.excerpt}
            </AppText>

            <div className="sm:gap-6 sm:flex-row flex flex-col gap-4 mt-8">
              <AppIconButton
                icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                iconOnRightSide
                label={study.ctaLabel}
                intent="ghost"
                link={study.ctaLink}
                bg={bg || "dark"}
              />
            </div>
          </div>
        </div>
      </div>
    </SwiperSlide>
  );
};
