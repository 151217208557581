/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMobilePhone } from "validator";
import * as z from "zod";

export const createDynamicSchemaSupportThirdTab = (dataGroups: any[]): Record<string, z.ZodTypeAny> => {
  const res: any = {};

  (dataGroups || []).forEach((group: any) => {
    if (group.groupType === "fieldGroupOptionCards") {
      // ! special case for cards .... cards are not packed as group -> rows -> fields, but instead all the data is in the group
      res.cards = z.string().min(1, { message: group.requiredError });
      return;
    }

    (group.rows || []).forEach((row: any) => {
      (row.fields || []).forEach((field: any) => {
        if (field.fieldType === "fieldCalendar") {
          res[field.name] = z.date({ message: field.requiredError });
        } else if (field.fieldType === "fieldPhone") {
          if (field.required) {
            res[field.name] = z.string().max(256).refine(isMobilePhone);
          } else {
            res[field.name] = z.string().max(256).refine(isMobilePhone).optional().or(z.literal(""));
          }
        } else if (field.fieldType === "fieldText") {
          if (field.name === "Your best offer") {
            // ! special case - inside unit info, available only if OBO true
            res[field.name] = z.coerce
              .number({ invalid_type_error: "Price must be a number" })
              .refine((val) => `${val}`.length <= field.max || 20, field.maxError || "Maximum 20 characters")
              .optional();
          } else {
            if (field.required) {
              res[field.name] = z
                .string()
                .min(field.min || 2, { message: field.minError || field.requiredError })
                .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
            } else {
              res[field.name] = z
                .string()
                .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" })
                .optional();
            }
          }
        } else if (field.fieldType === "fieldGoogleAddress") {
          res[field.name] = z
            .string()
            .min(field.min || 2, { message: field.minError || field.requiredError })
            .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
        } else if (field.fieldType === "fieldDropdown") {
          if (field.required) {
            res[field.name] = z
              .string()
              .min(field.min || 1, { message: field.minError || field.requiredError })
              .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
          } else {
            res[field.name] = z
              .string()
              .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" })
              .optional();
          }
        } else if (field.fieldType === "fieldEmail") {
          if (field.required) {
            res[field.name] = z.string().max(256).email({ message: field.requiredError });
          } else {
            res[field.name] = z.string().max(0).or(z.string().email());
          }
        } else if (field.fieldType === "fieldTextArea") {
          if (field.required) {
            res[field.name] = z
              .string()
              .min(field.min || 1, { message: field.minError || field.requiredError })
              .max(field.max || 2000, { message: field.maxError || "Maximum 2000 characters" });
          } else {
            res[field.name] = z
              .string()
              .max(field.max || 2000, { message: field.maxError || "Maximum 2000 characters" })
              .optional();
          }
        }
      });
    });
  });

  return res;
};
