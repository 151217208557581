"use client";

import React from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import Carousel from "../Carousel";
import { SwiperSlide } from "swiper/react";
import Image from "next/image";
import { cn } from "@/lib/utils";

interface IUseCaseHeader {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  media: { url: string; alt: string; placeholder: string }[];
  paddingBottom?: string;
}

const UseCaseHeader = ({ label, heading, subheading, media, paddingBottom }: IUseCaseHeader) => {
  return (
    <>
      <ContentLayout bgColorClass="bg-navy-blue" innerClass="sm:pt-[80px] pt-[48px] pb-0 sm:pb-0">
        <div className="flex flex-col max-w-[736px]">
          {label && (
            <AppText type="LABEL_LARGE" className="text-mellow mb-2">
              {label}
            </AppText>
          )}

          {heading && (
            <AppText type="HEADLINE_LARGE" className="mb-4 text-white">
              {heading}
            </AppText>
          )}

          {subheading && (
            <AppText type="SUB_HEADING_MEDIUM" className="mb-10 text-white">
              {subheading}
            </AppText>
          )}
        </div>
      </ContentLayout>

      <div className={cn("bg-navy-blue flex justify-center sm:pb-[80px] pb-[48px]", paddingBottom)}>
        {!!media.length && (
          <Carousel
            wrapperClassName={"flex -ml-40 justify-center"}
            slidesPerView="auto"
            spaceBetween={0}
            paginationType={"none"}
            cardsOverflow={false}
          >
            {media.map((img: { url: string; alt: string; placeholder: string }, idx: number) => {
              return (
                <SwiperSlide key={idx} className="px-4 !w-auto">
                  <Image
                    priority
                    src={img.url}
                    alt={img.alt}
                    sizes="100vw"
                    width={0}
                    height={0}
                    // sizes="700px"
                    // fill
                    // placeholder="blur"
                    // blurDataURL={img.placeholder}
                    className="h-[381px] max-w-[305px] object-cover w-full rounded-[6px]"
                  />
                </SwiperSlide>
              );
            })}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default UseCaseHeader;
