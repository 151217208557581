/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @next/next/no-img-element */
"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import AppText from "../AppText";
import { useMediaQuery } from "@/hooks/mediaQuery";
import Image from "next/image";
import ContentLayout from "../layouts/ContentLayout";
import { SolutionCategoryDots } from "../SolutionCategoryDots";

export interface IWorksiteTab {
  id: string;
  label: string;
  imgSrc: string;

  dots: {
    top: number;
    left: number;
    id: string;
    info: {
      label: string;
      heading: string;
      subheading: string;
      ctaLink: string;
    };
  }[];
}

export interface ISolutionCategoryHeader {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  imgSrc: string;
  imgAlt: string;
  dots: IDot[];
  paddingBottom?: string;
}

export interface IDot {
  id: string;
  top: number;
  left: number;
  heading: string;
}

let mouseDown = false;
let startX = 0,
  scrollLeft = 0;

// old mocks "mockSolutionCategoryHeader"
const SolutionCategoryHeader = ({
  label,
  heading,
  subheading,
  id,
  imgSrc,
  imgAlt,
  dots,
  paddingBottom,
}: ISolutionCategoryHeader) => {
  const [visibleTooltip, setVisibleTooltip] = useState<{ idx: number; isLeft: boolean; top: number } | null>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery("(max-width: 640px)");

  const move = (e: MouseEvent) => {
    e.preventDefault();

    if (cursorRef?.current) {
      cursorRef.current.style.left = `${e.clientX - 37}px`;
      cursorRef.current.style.top = `${e.clientY - 37}px`;
    }

    if (!mouseDown || !heroRef.current) {
      return;
    }
    const x = e.pageX - heroRef.current.offsetLeft;
    const scroll = x - startX;
    heroRef.current.scrollLeft = scrollLeft - scroll;
  };

  const stopDragging = useCallback(() => {
    mouseDown = false;
  }, []);

  const startDragging = (e: MouseEvent) => {
    e.preventDefault();

    mouseDown = true;

    if (heroRef.current) {
      startX = e.pageX - heroRef.current.offsetLeft;
      scrollLeft = heroRef.current.scrollLeft;
    }
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      setVisibleTooltip(null);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (heroRef.current) {
      heroRef.current.addEventListener("mousemove", move);
      heroRef.current.addEventListener("mousedown", startDragging);
      heroRef.current.addEventListener("mouseup", stopDragging);
      heroRef.current.addEventListener("mouseleave", stopDragging);
    }

    return () => {
      if (heroRef.current) {
        heroRef.current.removeEventListener("mousemove", move);
        heroRef.current.removeEventListener("mousedown", startDragging);
        heroRef.current.removeEventListener("mouseup", stopDragging);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        heroRef.current.removeEventListener("mouseleave", stopDragging);
      }
    };
  }, [stopDragging]);

  const handleDotsParentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target && target.id === "dotsParent") setVisibleTooltip(null);
  };

  return (
    <ContentLayout
      bgColorClass="bg-navy-blue"
      id={id}
      innerClass="sm:pb-0 pb-0 pt-12 sm:pt-20"
      paddingBottom={paddingBottom}
    >
      <div className="flex flex-col items-center">
        <div className="max-w-[736px] mb-12">
          {label && (
            <AppText type="LABEL_LARGE" className="text-mellow mb-1 text-center">
              {label}
            </AppText>
          )}

          <AppText type="HEADLINE_MEDIUM" as="h1" className="mb-4 text-center text-white">
            {heading}
          </AppText>
          <AppText type="BODY_MEDIUM" className="mb-2 text-center text-white">
            {subheading}
          </AppText>
        </div>

        <div className="bg-navy-blue relative w-full">
          {/** background image */}
          <div className="w-full">
            <Image
              priority
              // unoptimized={true}
              src={imgSrc}
              alt={imgAlt}
              className="object-contain w-full max-h-[460px]"
              sizes="100vw"
              width={0}
              height={0}
            />
          </div>

          {visibleTooltip !== null && (
            <div
              onClick={() => setVisibleTooltip(null)}
              className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent"
            />
          )}

          {/** dots */}
          <div onClick={handleDotsParentClick} id="dotsParent" className="absolute inset-0 z-30">
            {(dots || []).map((dot: IDot, idx: number) => {
              return (
                <SolutionCategoryDots
                  key={idx}
                  visibleTooltip={visibleTooltip}
                  setVisibleTooltip={setVisibleTooltip}
                  idx={idx}
                  dot={dot}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default SolutionCategoryHeader;
