export const FormFooterRecaptchaPolicy = () => {
  return (
    <div className="pt-14 flex flex-col items-center justify-center w-full pb-4 align-middle">
      <div className="text-black-60 text-balance max-w-2xl text-sm text-center">
        This site is protected by reCAPTCHA and the Google
        <a
          href="https://policies.google.com/privacy"
          rel="noopener noreferrer"
          target="_blank"
          className="text-royal-blue mx-1"
        >
          Privacy Policy
        </a>
        and
        <a
          href="https://policies.google.com/terms"
          rel="noopener noreferrer"
          target="_blank"
          className="text-royal-blue mx-1"
        >
          Terms of Service
        </a>
        apply.
      </div>
    </div>
  );
};
