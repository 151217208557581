/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import AppText from "@/components/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { cn } from "@/lib/utils";
import Image from "next/image";
import Carousel from "@/components/Carousel";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "@/hooks/mediaQuery";
import Dropdown from "@/components/Dropdown";
import ContentLayout from "./layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";
import { Check } from "lucide-react";

type UnitConfiguratorTabSection = {
  heading: string;
  configurations: string[];
};

type UnitConfiguratorTab = {
  id: string;
  heading: string;
  tabLabel: string;
  content: any;
  images: { url: string; alt: string; placeholder: string }[];
  footNote?: string;
  ctaLabel: string;
  ctaLink: string;
  sections: UnitConfiguratorTabSection[];
};

interface ProductData {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  tabs: UnitConfiguratorTab[];
  paddingBottom?: string;
}

const UnitConfigurator: React.FC<ProductData> = ({ label, heading, subheading, tabs, id, paddingBottom }) => {
  const [selectedTab, setSelectedTab] = useState<UnitConfiguratorTab>(tabs[0]);
  const [selectedProduct, setSelectedProduct] = useState<any>(
    selectedTab?.images?.[0] ?? { url: "", alt: "", placeholder: "" }
  );
  const slidesPerView = selectedTab?.images?.length ?? 0;
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const replaceTabsWithDropdown: boolean = useMediaQuery("(max-width: 640px)") && tabs.length > 2;

  if ((tabs?.length ?? 0) === 0) {
    console.log(tabs, "In UnitConfigurator tabs are empty!!! Can't render section!");
    return null;
  }

  const onTabSelect = (tabId: string) => {
    const tab = tabs.find((t) => t.id === tabId);
    if (tab) {
      setSelectedTab(tab);
      setSelectedProduct(tab.images[0]);
    }
  };

  const renderTabs = ({ tabLabel, tabId }: { tabLabel: string; tabId: string }) => {
    const isSelected = tabId === selectedTab.id;

    return (
      <button
        key={tabId}
        onClick={() => onTabSelect(tabId)}
        className={cn(
          "px-4 font-sairaSemiCondensed py-2 rounded-[100px] outline-mellow font-[500] text-14  leading-[1.7]",
          isSelected ? "bg-royal-blue text-white" : "bg-black-10 text-black hover:bg-black-20 "
        )}
      >
        {tabLabel}
      </button>
    );
  };

  const renderImage = (imageObj: { url: string; alt: string; placeholder: string }, index: number) => {
    return (
      <SwiperSlide key={index} className="w-[96px] min-h-[96px] max-h-[200px] lg:max-h-full relative aspect-[16/9]">
        <Image
          onClick={() =>
            setSelectedProduct({ url: imageObj.url, alt: imageObj.alt, placeholder: imageObj.placeholder })
          }
          src={imageObj.url}
          alt={imageObj.alt}
          sizes="(max-width: 1024px) 100vw, 300px"
          fill
          placeholder="blur"
          blurDataURL={imageObj.placeholder}
          className="object-cover w-full h-full"
        />
      </SwiperSlide>
    );
  };

  const renderTabSection = (s: UnitConfiguratorTabSection) => {
    return (
      <div key={s.heading} className={"mt-[40px] pl-[16px] flex flex-col border-l-[1px] border-l-black-40"}>
        <AppText type={"BODY_MEDIUM_EMPHASIS"}>{s.heading}</AppText>

        <div className={"mt-2 flex flex-wrap gap-y-4 gap-x-8"}>
          {s.configurations.map((item, index) => (
            <div key={index} className="flex items-center">
              {/* <CheckMark /> */}

              <Check size={20} color="#0058AC" className="min-w-[20px]" aria-label="check icon" />

              <AppText className="ml-2" type="BODY_SMALL_EMPHASIS">
                {item}
              </AppText>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <ContentLayout id={id} innerClass="sm:pt-[80px] pt-[48px] sm:pb-0 pb-0" paddingBottom={paddingBottom}>
        <div className={"flex flex-col items-center"} id="our-range">
          {label && (
            <AppText type="LABEL_LARGE" className="text-royal-blue text-center">
              {label}
            </AppText>
          )}
          <AppText type="HEADLINE_MEDIUM" className="mt-[8px] text-center">
            {heading}
          </AppText>

          <AppText type="SUB_HEADING_MEDIUM" className="mt-[16px] text-center">
            {subheading}
          </AppText>
        </div>
      </ContentLayout>

      <div className="w-full">
        {replaceTabsWithDropdown ? (
          <Dropdown
            className={"mt-[48px]"}
            items={tabs.map((t) => ({ label: t.heading, id: t.id }))}
            selectedItem={{ label: selectedTab.heading, id: selectedTab.id }}
            onItemClick={onTabSelect}
          />
        ) : tabs.length > 1 ? (
          <ContentLayout innerClass="sm:py-0 py-0 items-center justify-center flex">
            <div className="mt-[48px] max-w-[80%] gap-4 flex flex-wrap justify-center items-center w-full flex-1">
              {[...tabs].map((t) => renderTabs({ tabLabel: t.tabLabel, tabId: t.id }))}
            </div>
          </ContentLayout>
        ) : null}
      </div>

      <ContentLayout innerClass="sm:pb-[80px] pb-[48px] sm:pt-0 pt-0">
        {/** separator */}
        <div className={"mt-[48px] hidden sm:block bg-black-20 h-[2px] w-full"} />

        {/** tab content */}
        <div className={"flex flex-1 max-lg:flex-col-reverse mt-4 lg:mt-12"}>
          <div className={"flex flex-col flex-[0.5] mr-[40px]"}>
            <AppText type={"TITLE_LARGE"} className="my-4">
              {selectedTab.heading}
            </AppText>
            <RichTextRenderer richTextDocument={selectedTab.content} id={`rt-${id}`} locations />
            {selectedTab.sections.map(renderTabSection)}
            {!!selectedTab.footNote && (
              <AppText type="CAPTION_MEDIUM" className="mt-8">
                {selectedTab.footNote}
              </AppText>
            )}
            {typeof selectedTab.ctaLabel === "string" && typeof selectedTab.ctaLink === "string" && (
              <AppButton
                label={selectedTab.ctaLabel}
                intent="secondary"
                link={selectedTab.ctaLink}
                className={"mt-10"}
              />
            )}
          </div>
          <div className={"relative flex flex-[0.5] flex-col justify-center items-center overflow-hidden"}>
            {!isSmallScreen && (
              <div className="w-[80%] relative aspect-[16/9]">
                <Image
                  src={selectedProduct.url}
                  alt={selectedProduct.alt}
                  sizes="(max-width: 1024px) 0vw, 520px"
                  fill
                  placeholder="blur"
                  blurDataURL={selectedProduct.placeholder}
                  className="object-cover w-full max-h-[323px]"
                />
              </div>
            )}

            {!!selectedTab.images.length && (
              <div className={"relative w-full flex justify-center max-lg:justify-end flex-col mt-6"}>
                <Carousel
                  key={slidesPerView}
                  wrapperClassName={cn("flex flex-1 relative ")}
                  loop={true}
                  slidesPerView={slidesPerView}
                  slidesPerGroup={1}
                  spaceBetween={16}
                  modules={[Pagination]}
                  rewind={true}
                  pagination={isSmallScreen && { el: ".swiper-custom-pagination" }}
                  paginationType={"sideArrows"}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    640: { slidesPerView: 1 },
                    1024: { slidesPerView: Math.max(slidesPerView - 1, 1) },
                    1280: { slidesPerView: slidesPerView },
                    1536: { slidesPerView: slidesPerView },
                  }}
                >
                  {selectedTab.images.map((img: any, index: number) => renderImage(img, index))}
                </Carousel>
                {isSmallScreen && (
                  <div className="swiper-custom-pagination flex items-end justify-center w-full pb-3 mt-6" />
                )}
              </div>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default React.memo(UnitConfigurator);
