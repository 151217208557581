/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";

import { Form } from "@/components/ui/form";
import AppButton from "@/components/buttons/AppButton";
import { getGeocodingAddress, preparePayloadRequestSupport } from "@/components/form/helpers";
import { FieldCalendar, FieldDropdown, FieldGoogleAddress, FieldPhone, FieldText, FieldTextArea } from "./FormElements";
import { useFormData } from "@/store/servicesFormsStore";
import AppText from "../AppText";
import { cn } from "@/lib/utils";
import APIClient from "@/app/[lang]/request-service/apiClient";
import { createDynamicSchemaSupportThirdTab } from "./schemaHelper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";

const FormRequestSupportStep3 = ({ formData }: { formData: any }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formSchema = z.object({});

  const setStepAndFormDataThirdTab = useFormData((state) => state.setStepAndFormDataThirdTab);
  const formDataStore = useFormData((state) => state.formDataThirdTab);
  const serviceType = useFormData((state) => state.formData.Request_Type__c);

  const [submitting, setSubmitting] = useState(false);
  const [selectedPlace, setSelectedPlaceBuy] = useState<google.maps.places.PlaceResult | null>(null);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);

  const New_Site_Address__c = useRef(""); // actual address, named same as payload key
  const googlePlacesTouched = useRef(false);
  const serviceHeading = useRef("");

  const router = useRouter();

  const dataGroups = useMemo(() => {
    if (!formData || !formDataStore || !serviceType) {
      return [];
    }

    const scenario = formData.groups?.[0]?.scenarios[serviceType];

    serviceHeading.current = scenario.heading || "";

    if (!scenario) {
      return [];
    }

    return scenario.groups;
  }, [formData, formDataStore, serviceType]);

  const formDefaultValues = useMemo(() => {
    const res: any = {};

    (dataGroups || []).forEach((group: any) => {
      (group.rows || []).forEach((row: any) => {
        (row.fields || []).forEach((field: any) => {
          res[field.name] = formDataStore[field.name] || "";
        });
      });
    });

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGroups]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onBlur",
  });

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const geoRes = await getGeocodingAddress(selectedPlace?.formatted_address);

      form.setValue("New_Site_country", geoRes.country);
      form.setValue("New_Site_state", geoRes.state);
      form.setValue("New_Site_zip", geoRes.zipCode);
      form.setValue("New_Site_city", geoRes.city);

      if (geoRes.address) {
        if (geoRes.streetNumber) {
          New_Site_Address__c.current = `${geoRes.streetNumber} ${geoRes.address}`;
        } else {
          New_Site_Address__c.current = `${geoRes.address}`;
        }
      } else if (geoRes.formatted_address) {
        New_Site_Address__c.current = geoRes.formatted_address;
      } else {
        New_Site_Address__c.current = geoRes.neighborhood;
      }

      setTimeout(() => {
        form.trigger("New_Site_country");
        form.trigger("New_Site_state");
        form.trigger("New_Site_zip");
        form.trigger("New_Site_city");
      }, 500);
    }
  };

  useEffect(() => {
    if (selectedPlace && selectedPlace?.formatted_address) {
      getSiteData();
      form.setValue("New_Site_address", "valid", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      if (googlePlacesTouched.current) {
        form.setValue("New_Site_address", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const createDynamicSchema = () => {
      const res = createDynamicSchemaSupportThirdTab(dataGroups);
      const formSchema = z.object({ ...res });
      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [dataGroups]);

  const deliveryAddressBlur = () => {
    googlePlacesTouched.current = true;
  };

  const handleDeliveryAddressOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setSelectedPlaceBuy(null);
    }
  };

  async function onSubmit() {
    if (!executeRecaptcha) {
      toast.error("Error submitting request - reCAPTCHA not loaded");
      return;
    }

    setSubmitting(true);

    const token = await executeRecaptcha("submit_request_support");

    const recaptchaResponse = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const recaptchaData = await recaptchaResponse.json();

    if (!recaptchaData.success) {
      console.log(recaptchaData);
      toast.error("reCAPTCHA verification failed");
      setSubmitting(false);
      return;
    }

    console.log("reCAPTCHA verification successful", recaptchaData);

    const formValues = form.getValues();

    const storeState = useFormData.getState();

    const payload = { ...formValues, ...storeState.formData };

    if (New_Site_Address__c.current) {
      payload.New_Site_Address__c = New_Site_Address__c.current;
    }

    delete payload.Requested_address;
    delete payload.New_Site_address;
    delete payload.New_Site_Country__c;

    const updatedPayload = preparePayloadRequestSupport(payload);

    const api = new APIClient();

    api
      .post("/services/requestAService", updatedPayload)
      .then(() => {
        setSubmitting(true);
        router.push("/request-service/thank-you");
      })
      .catch((err) => {
        toast.error("Error submitting request");
        console.log(err);
        setSubmitting(false);
      });
  }

  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  const handleBackButton = () => {
    setStepAndFormDataThirdTab(2, form.getValues());
  };

  return (
    <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 pt-2 bg-white">
      <div className="pb-4">
        <AppText type={"HEADLINE_SMALL"}>{serviceHeading.current}</AppText>
      </div>
      <Form {...form}>
        <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
          {(dataGroups || []).map((group: any) => (
            <FormGroupWrapperForThirdTab
              key={group.id}
              groupHeading={group.heading}
              groupDescription={group.description}
              noBottomPadding={group.groupType === "fieldGroupOptionCards"}
            >
              {(group.rows || []).map((row: any) => (
                <div key={row.id} className="lg:flex-row flex flex-col gap-6">
                  {row.fields?.length &&
                    row.fields.map((field: any) => {
                      if (field.fieldType === "fieldText") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                            footnote={field.footnote}
                            className={field.name === "New_Site_Contact__c" ? "" : "lg:max-w-[calc(50%-12px)]"}
                          />
                        );
                      } else if (field.fieldType === "fieldPhone") {
                        return (
                          <FieldPhone
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldCalendar") {
                        return (
                          <FieldCalendar
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                            className="lg:w-[calc(50%-12px)]"
                          />
                        );
                      } else if (field.fieldType === "fieldGoogleAddress") {
                        return (
                          <FieldGoogleAddress
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                            deliveryAddressBlur={deliveryAddressBlur}
                            setSelectedPlaceBuy={setSelectedPlaceBuy}
                            handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                            className="lg:w-[calc(50%-12px)]"
                          />
                        );
                      } else if (field.fieldType === "fieldTextArea") {
                        return (
                          <FieldTextArea
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            placeholder={field.placeholder}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldDropdown") {
                        return (
                          <FieldDropdown
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            label={field.label}
                            options={field.options}
                          />
                        );
                      } else if (field.fieldType === "fieldEmail") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              ))}
            </FormGroupWrapperForThirdTab>
          ))}

          <div className="mt-10">
            <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

            <div className="flex flex-row flex-wrap justify-between gap-4 bg-white">
              <AppButton
                intent="secondary"
                label={formData.ctaBack}
                type="button"
                className="px-10"
                onMouseDown={handleBackButton}
              />

              {submitting ? (
                <button
                  id="button-request-quote"
                  className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-2
                  transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
                  disabled
                >
                  <div
                    className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                  ></div>
                  <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                    {formData.ctaSubmitting || "Submitting..."}
                  </AppText>
                </button>
              ) : (
                <AppButton
                  intent="primary"
                  label={formData.ctaSubmit}
                  type="submit"
                  className="px-10"
                  disabled={submitting}
                  onMouseDown={handleFormSubmit}
                />
              )}
            </div>
          </div>
        </form>
      </Form>
      <FormFooterRecaptchaPolicy />
    </div>
  );
};

export default FormRequestSupportStep3;

const FormGroupWrapperForThirdTab = ({
  groupHeading,
  groupDescription,
  children,
  noBottomPadding,
}: {
  groupHeading: string;
  groupDescription?: string;
  children: React.ReactNode;
  noBottomPadding?: boolean;
}) => {
  const heading = (groupHeading || "").split("*").map((item) => item.trim());

  return (
    <div className="flex gap-[18px] py-4">
      <div className="flex-1 flex flex-col pb-[60px] gap-[12px]">
        <AppText type="TITLE_MEDIUM" className="flex flex-col">
          {heading.map((item: string) => (
            <span key={item}>{item}</span>
          ))}
        </AppText>
        {groupDescription && (
          <AppText type="BODY_MEDIUM" className={cn(noBottomPadding ? "pb-0" : "pb-3")}>
            {groupDescription}
          </AppText>
        )}
        {children}
      </div>
    </div>
  );
};
