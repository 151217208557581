"use client";

import Image from "next/image";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { AppIconButton } from "../buttons/AppIconButton";

import { format } from "date-fns";
import { ChevronRight } from "lucide-react";

export interface IArticle {
  id: string;
  heading: string;
  image?: string;
  placeholder?: string;
  imgAlt?: string;
  label: string;
  ctaLink: string;
  ctaLabel: string;
}

export interface IFeaturedArticlesHeader {
  id: string;
  featuredHeading: string;
  listHeading: string;
  featuredArticle: IArticle;
  listArticles: IArticle[];
  paddingBottom?: string;
}

const FeaturedArticlesHeader: React.FC<IFeaturedArticlesHeader> = ({
  id,
  featuredHeading,
  featuredArticle,
  listArticles,
  listHeading,
  paddingBottom,
}) => {
  return (
    <ContentLayout bgColorClass="bg-navy-blue" id={id} paddingBottom={paddingBottom}>
      <div className="lg:grid-cols-12 sm:gap-4 lg:gap-6 xl:gap-10 grid grid-cols-4 gap-2">
        <div className="lg:col-span-7 xxl:col-span-6 flex flex-col col-span-4">
          <AppText type="HEADLINE_SMALL" as="h2" className="text-mellow mb-10">
            {featuredHeading}
          </AppText>

          <div className="flex flex-col overflow-hidden h-[100%]">
            <div className="relative w-full overflow-hidden aspect-[1.75] md:aspect-[1.5] xl:aspect-[1.75]">
              <Image
                className="object-cover w-full"
                sizes="(max-width: 1024px) 100vw, (max-width: 1536px) 50vw, 500px"
                fill
                placeholder="blur"
                blurDataURL={featuredArticle.placeholder}
                src={featuredArticle.image || "/placeholders/no-preview.png"}
                alt={featuredArticle.imgAlt || ""}
              />
            </div>

            <div className="flex flex-col items-start flex-1 py-4">
              <AppText className="mb-2 text-white" type="BODY_SMALL">
                {format(featuredArticle.label ?? 0, "MMMM dd, yyyy")}
              </AppText>

              <AppText className="line-clamp-2 mb-4 text-white" type="TITLE_LARGE">
                {featuredArticle.heading}
              </AppText>

              <AppIconButton
                link={featuredArticle.ctaLink}
                tabIndex={-1}
                intent="ghost"
                label={featuredArticle.ctaLabel}
                icon={<ChevronRight size={20} />}
                iconOnRightSide
                bg="dark"
              />
            </div>
          </div>
        </div>

        <div className="xxl:col-span-5 lg:col-start-9 xxl:col-start-8 flex flex-col col-span-4 col-start-1">
          <AppText type="HEADLINE_SMALL" className="text-mellow mb-10">
            {listHeading}
          </AppText>

          <div className="gap-y-10 gap-x-6 lg:flex-col justify-normal lg:justify-between flex flex-row flex-wrap">
            {listArticles.map((item, idx) => (
              <div
                key={idx}
                className="border-l-[1px] border-mellow pl-8 flex flex-col justify-between max-w-[380px] lg:max-w-full"
              >
                <div>
                  <AppText type="BODY_SMALL" className="mb-2 text-white">
                    {format(item.label ?? 0, "MMMM dd, yyyy")}
                  </AppText>
                  <AppText type="SUB_HEADING_SMALL" className="text-white">
                    {item.heading}
                  </AppText>
                </div>

                <div className="mt-[20px]">
                  <AppIconButton
                    intent="ghost"
                    label={item.ctaLabel}
                    icon={<ChevronRight size={20} />}
                    iconOnRightSide
                    link={item.ctaLink}
                    bg="dark"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default FeaturedArticlesHeader;
